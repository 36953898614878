<!--company details-->
<template>
    <div class="job_details">
        <div v-if="loading" class="loading-wrap">
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 company_details">
                    <!--left-->
                    <div class="left">
                        <div id=" head">
                            <header>
                                <div class="img_logo_1 ">
                                    <img style="width: 13%; margin: 5px 10px 10px 5px" v-if="details.logo != ''" :src="IMAGE_URL + details.logo" alt="HTML5 Icon">
                                    <img style="width: 13%; margin: 5px 10px 10px 5px" src="../../assets/no-image.png" alt="" v-else>
                                    <span style="font-weight: bold; color: #2C3B63" class="company_name1" v-html="details.ner_mnbrigad"></span>
<!--                                    <Icon style="color: #EFBC11; float: right" class="detailsIcon" type="ios-arrow-forward"/>-->
                                </div>
                            </header>
                        </div>
                        <div class="ROW">
                            <div class="div_job">
                                <span class="job_name">{{ details.ajil }}</span>
                            </div>
                            <!-- ГҮЙЦЭТГЭХ ҮНДСЭН ҮҮРЭГ-->
                            <div class="list_1" style="width: 100%">
                                <p class="contact">{{ 'guitsetgeh_undsen_uureg' | translate }}</p>
                                <p class="list_ul" v-html="details.uureg"></p>
                            </div>
                            <!-- ТАВИГДАХ ШААРДЛАГА-->
                            <div class="list_1">
                                <p class="contact">{{ 'tavigdah_shaardlaga' | translate }}</p>
                                <ul class="list_ul" v-html="details.shaardlaga">

                                </ul>
                            </div>
                            <!-- НЭМЭЛТ МЭДЭЭЛЭЛ-->
                            <div class="list_1">
                                <p class="contact">{{ 'nemelt_medeelel' | translate }}</p>
                                <ul class="list_ul" v-html="details.nemelt"></ul>
                            </div>
                            <!--БУСАД МЭДЭЭЛЭЛ-->
                            <div class="list_1">
                                <p class="contact">{{ 'busad_medeelel' | translate }}</p>
                                <div class="other_information ">
                                    <table>
                                        <tr>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'ajliin_turul' | translate }}</p>
                                                    <b>{{ details.ajiliin_torol }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'tsalin_hulsnii_turul' | translate }}</p>
                                                    <b>{{ details.tsalin_torol }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'tsagiin_turul' | translate }}</p>
                                                    <b>{{ details.tsagiin_torol }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'aimag_niislel' | translate }}</p>
                                                    <b>{{ details.aimagname }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'sum_duureg' | translate }}</p>
                                                    <b>{{ details.sumname }}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd">
                                                    <p>{{ 'bag_horoo' | translate }}</p>
                                                    <b>{{ details.bagname }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="tr_different_border">
                                            <td>
                                                <div class="padd1 ">
                                                    <p>{{ 'zarlasan_ognoo' | translate }}</p>
                                                    <b>{{ details.e_ognoo | date}}</b>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="padd1 ">
                                                    <p> {{ 'anket_avah_ognoo' | translate }}</p>
                                                    <b class="clr">{{ details.d_ognoo | date }}</b>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="userToken != null && userToken != false && user">
                                            <td colspan="2" style="border: none">
                                                <Button type="primary" class="float-right footer_sanal_btn"
                                                        @click="uniinSanalShow = true">{{
                                                        'sanal_ilgeeh' | translate
                                                    }}
                                                </Button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <!--ТУХАЙН АЖЛЫГ ГҮЙЦЭТГЭХЭД ШААРДЛАГАТАЙ МЭРГЭЖИЛ БОЛОН АЖИЛТАН-->
                            <div class="list_1">
                                <p class="contact">
                                    {{ 'hun' | translate }}
                                </p>
                                <!--humuus-->
                                <div class="flex_1" v-for="(detail, index) in list" :key="index">
                                    <div class="job">
                                        <div>{{ detail.ajitan_teknik }}</div>
                                    </div>
                                    <p>{{ detail.toos }}</p>
                                </div>
                            </div>
                            <!--холбоо барих-->
                            <div class="list_1">
                                <p class="contact">{{ 'holboo_barih' | translate }}</p>
                                <div class="row">
                                    <div class="col-sm-8 company_socials">
                                        <div class="row row_1">
                                            <a :href="details.face" target="_blank">
                                                <i class="ivu-icon ivu-icon-logo-facebook"></i>
                                            </a>
                                            <a :href="details.twitter" target="_blank">
                                                <i class="ivu-icon ivu-icon-logo-twitter"></i>
                                            </a>
                                            <a :href="details.linkedin" target="_blank">
                                                <i class="ivu-icon ivu-icon-logo-linkedin"></i>
                                            </a>
                                        </div>
                                        <div class="row row_2">
                                            <div class="col-sm-1">
                                                <Icon type="md-map"/>
                                            </div>
                                            <div class="col-sm-10">
                                                <span class="address">Монгол улс, Улаанбаатар, {{details.sumname}}, {{details.bagname}}, {{ details.gudam }}, {{ details.toot }} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 address">
                                        <p>
                                            <Icon type="md-call"/> &nbsp; {{ details.utas_one }}
                                        </p>
                                        <p>
                                            <Icon type="md-call"/> &nbsp;&nbsp; {{ details.utas_two }}
                                        </p>
                                        <p>
                                            <Icon type="md-mail"/> &nbsp;&nbsp; {{ details.mail }}
                                        </p>
                                        <p>
                                            <Icon type="md-globe"/> &nbsp;&nbsp;{{ details.web }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!--ЗАРЛАГДСАН ИЖИЛ АЖЛУУД-->
                <div class="col-md-4 job_posting_details">
                    <!--right-->
                    <div class="right">
                        <div class="row_2">
                          <div class="title_1">
                            {{ 'zarlagdsan_ijil_zaruud' | translate }}
                            <svg class="svg_text_line">
                              <line x1="80" style="stroke:#EFBC11; stroke-width:3"/>
                            </svg>
                          </div>
                            <div class="p_table">
                                <table width="100%">
                                    <tbody>
                                    <!--tr-->
                                    <tr v-for="(other, index) in others" :key="index">
                                        <td>
                                            <div class="col_1 ">
                                                <router-link :to="`/br_a_z_details/${other.id}`">
                                                    <p>{{other.ajil}}</p>
                                                </router-link>
                                                <div class="sub_col ">
                                                    <router-link :to="`/br_a_z_details/${other.id}`">
                                                        <div class="c_name">{{ other.ner_mnbrigad }}</div>
                                                    </router-link>
                                                    <div class="text_bold">{{ 'where' | translate }}: <span
                                                            class="not_bold"> {{ other.aimagname }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="td_2_1">
                                                <p>{{ 'anket_avah_ognoo' | translate }}</p>
                                                <p class="p_text">{{ other.d_ognoo | date}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-model="uniinSanalShow" :title="'Send_a_quote' | translate" footer-hide width="600px">
            <dataform ref="sanalForm" v-if="uniinSanalShow" schemaID="409" :public="true" :onReady="readySanalForm"
                      :onSuccess="onSuccess"
            />
        </Modal>
    </div>
</template>

<script>

import {
    GET_BRGD_AJILIIN_ZAR_VIEW,
    GET_BRGD_AJILIIN_ZAR_VIEW_DETAILS,
    GET_BRGD_AJILIIN_ZAR_VIEW_DETAILS_AJILTAN_TOO, IMAGE_URL,
} from "../../graphql/queries";
import {mapGetters} from "vuex";

const Dataform = () => import(/* webpackChunkName: "Dataform-el" */'@lambda-platform/dataform/src/Dataform.vue')
export default {
    name: "brigad_ajiliin_zar_details",
    props: ['id'],
    data() {
        return {
            details: {},
            loading: true,
            list: [],
            IMAGE_URL: IMAGE_URL,
            uniinSanalShow: false,
            others: [],
        }
    },
    components: {
        "dataform": Dataform
    },
    computed: {
      ...mapGetters([
        'user',
        'userToken',
      ])
    },
    methods: {
        readySanalForm() {
            this.$refs.sanalForm.setModel("brigad_id", this.details.b_id, "select");
            this.$refs.sanalForm.setModel("zar_id", this.details.id, "select");
        },
        onSuccess() {
            this.uniinSanalShow = false;
        },
        getDetails() {
            this.loading = true;
            this.$apollo.query({
                query: GET_BRGD_AJILIIN_ZAR_VIEW_DETAILS,
                variables: {b_id: this.id.toString(),}
            }).then(({data}) => {
                this.details = data.ds_brigad_ajil_zar_view[0];
                this.loading = false;
            })
        },
        getAjiltanToo() {
            this.loading = true;
            this.$apollo.query({
                query: GET_BRGD_AJILIIN_ZAR_VIEW_DETAILS_AJILTAN_TOO,
                variables: {b_id: this.id.toString(),}
            }).then(({data}) => {
                this.list = data.ds_brigad_ajil_zar_view;
                this.loading = false;
            })
        },
        getDetailsOtherPostings() {
            this.loading = true;
            this.$apollo.query({
                query: GET_BRGD_AJILIIN_ZAR_VIEW,
            }).then(({data}) => {
                this.others = data.ds_brigad_ajil_zar_view;
                this.loading = false;
            })
        },
    },
    mounted() {
        this.getDetails();
        this.getAjiltanToo();
        this.getDetailsOtherPostings();
    },

}
</script>

<style scoped>

</style>



